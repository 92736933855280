import { useSubscribeInfinite, useSubscribeArray, useSubscribeObject, useArrayItem } from './utils';
import { getQueryClient } from './queryClient';
import { useCurrentOrganization } from './organization';
import { useSocketId } from './notify';

export const useOrganizations = () => useSubscribeArray('organizations');
export const useInvitations = organizationId => useSubscribeArray('invitations', organizationId);
export const useMembers = organizationId => useSubscribeArray('members', organizationId);
export const useBuildingHubs = buildingId => useSubscribeArray('buildingHubs', buildingId);
export const useUncapturedHubs = () => useSubscribeArray('uncapturedHubs');
export const useRetiringHubs = buildingId => useSubscribeArray('retiringHubs', buildingId);
export const useFaultStatistics = buildingId => useSubscribeObject('faultStatistics', buildingId);
export const useDevice = deviceId => useSubscribeObject('deviceById', deviceId);
export const useBuildingFaults = (buildingId, timestamp = 8640000000000000) => useSubscribeArray('buildingFaults', buildingId, timestamp);
export const useDevices = hubId => useSubscribeArray('hubDevices', hubId);
export const useCircuits = hubId => useSubscribeArray('hubCircuits', hubId);
export const useHubApplications = hubId => useSubscribeArray('hubApplications', hubId);
export const useBuildingCircuits = buildingId => useSubscribeArray('buildingCircuits', buildingId);
export const useHubSettings = hubId => useSubscribeObject('hubSettings', hubId);

export const useBuildingFault = (buildingId, faultId, timestamp = 8640000000000000) => useArrayItem('buildingFaults', faultId, buildingId, timestamp );

export const useJobs = (options = {}) => useSubscribeInfinite('jobs', options);
export const useUsers = (options = {}) => useSubscribeInfinite('adminUsers', options);
export const useUserActivity = (options = {}) => useSubscribeInfinite('userActivity', options);

export const useBuildings = () => {
    const organization = useCurrentOrganization();
    return useSubscribeArray('buildings', organization?.id);
};

export const useContacts = (buildingId, options = { sortModel: [{ field: 'name', sort: 'asc' }] }) => useSubscribeInfinite('buildingContacts', buildingId, options);
export const useContactById = (contactId) => useSubscribeObject('contactById', contactId);
export const useAccessGroups = (buildingId, options = { sortModel: [{ field: 'name', sort: 'asc' }] }) => useSubscribeArray('accessGroups', buildingId, options);
export const useAccessGroupById = (groupId) => useSubscribeObject('accessGroupById', groupId);

export const useSchedules = (buildingId, options = { sortModel: [{ field: 'name', sort: 'asc' }] }) => useSubscribeArray('buildingSchedules', buildingId, options);
export const useScheduleById = (scheduleId) => useSubscribeObject('scheduleById', scheduleId);

export const useHubSlots = buildingId => useSubscribeObject('hubSlots', buildingId);
export const useFilledHubSlots = buildingId => useSubscribeArray('filledHubSlots', buildingId);
export const useFilledDeviceSlots = buildingId => useSubscribeArray('filledDeviceSlots', buildingId);
export const useCircuitSlots = buildingId => useSubscribeArray('circuitSlots', buildingId);

export const useBranches = repo => useSubscribeArray('branches', repo);
export const useBuilds = (repo, branch, arch = 'any') => useSubscribeArray('builds', repo, branch, arch);
export const useApplications = () => useSubscribeObject('applications');

export const useBuildStatistics = () => useSubscribeObject('buildStatistics');
export const useBuildingDeviceSummary = buildingId => useSubscribeObject('buildingDeviceSummary', buildingId);
export const useBuildingDevices = buildingId => useSubscribeArray('buildingDevices', buildingId);

export const useWaterMeters = buildingId => useSubscribeArray('waterMeters', buildingId);
export const usePowerMeters = buildingId => useSubscribeArray('powerMeters', buildingId);
export const useWaterMeter = (hubId, start, end) => useSubscribeObject('waterMeter', hubId, start, end);
export const usePowerMeter = (hubId, start, end) => useSubscribeObject('powerMeter', hubId, start, end);
export const useButtonPresses = (buildingId, options = {}) => useSubscribeInfinite('buttonPresses', buildingId, options);
export const useHubActivity = (buildingId, options = {}) => useSubscribeArray('hubActivity', buildingId, options);

export const getSubscriptionObject = (key, id) => {
    const queryClient = getQueryClient();
    const queriesData = queryClient.getQueriesData(key);
    const data = queriesData[queriesData.length - 1]?.[1];
    if (!data) return;
    const item = data?.find(item => item.id === id);
    return item;
};

export const useOnline = () => {
    const socketId = useSocketId();
    return !!socketId;
};
